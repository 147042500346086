
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Button',

  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      validator: (value: string) => {
        return ['primary', 'border', 'secondary', 'yellow', 'white'].includes(
          value
        )
      },
      default: 'primary',
    },
    size: {
      validator: (value: string) => {
        return ['sm', 'md', 'lg'].includes(value)
      },
      default: 'md',
    },
    shadow: {
      validator: (value: string) => {
        return ['primary', 'secondary', 'yellow', 'white'].includes(value)
      },
      default: 'white',
    },
    handleClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      colorClass: `btn-color-${props.color}`,
      sizeClass: `btn-size-${props.size}`,
      shadowClass: `btn-shadow-${props.shadow}`,
    }
  },
})
