declare let gtag: Function // eslint-disable-line
import { createStore, Commit, Dispatch } from 'vuex'
import { StoreState, Guide, Quiz } from '../types/'
import axios from 'axios'

axios.defaults.auth = {
  username: process.env.VUE_APP_API_ID,
  password: process.env.VUE_APP_API_PASS,
}

// Develop用のモック処理
if (process.env.NODE_ENV === 'development') {
  axios.interceptors.request.use(
    (config) => {
      // console.log(config)

      if (
        config.url ===
          `${process.env.VUE_APP_API_BASE}/wp-json/v1/authenticate` &&
        config.params.key === '456'
      ) {
        config.headers.Prefer = 'example=fail'
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    async (response) => {
      await new Promise((resolve) => setTimeout(() => resolve(''), 1000))
      return response
    },
    async (error) => {
      await new Promise((resolve) => setTimeout(() => resolve(''), 1000))
      return Promise.reject(error)
    }
  )
}

export default createStore<StoreState>({
  state: {
    isInitialized: false,
    isOffline: false,
    isLoading: false,
    isScroll: true,
    lang: 'ja',
    errorModal: {
      type: '',
      show: false,
    },
  },

  mutations: {
    initializedApp(state): void {
      state.isInitialized = true
    },

    unInitializedApp(state): void {
      state.isInitialized = false
    },

    startLoading(state): void {
      state.isLoading = true
    },

    endLoading(state): void {
      state.isLoading = false
    },

    setOfflineView(state): void {
      state.isOffline = true
    },

    fixBody(state): void {
      state.isScroll = false
    },

    unFixBody(state): void {
      state.isScroll = true
    },

    setLang(state, lang): void {
      state.lang = lang
    },
  },

  actions: {
    showErrorModal(
      {
        state,
        commit,
      }: {
        state: StoreState
        commit: Commit
      },
      type: 'contents' | 'auth'
    ): void {
      if (!state.errorModal.show) gtag('event', `error_${type}`)

      commit('fixBody')
      state.errorModal.type = type
      state.errorModal.show = true
    },

    closeErrorModal({
      state,
      commit,
    }: {
      state: StoreState
      commit: Commit
    }): void {
      commit('unFixBody')
      state.errorModal.type = ''
      state.errorModal.show = false
    },

    authenticateKey(
      { state }: { state: StoreState },
      key: string
    ): Promise<boolean> | void {
      // console.log(state)

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_BASE}/wp-json/v1/authenticate`, {
            params: {
              key: key,
            },
          })
          .then((res) => {
            return resolve(res.data.result)
          })
          .catch((error) => {
            console.log(error)
            return reject()
          })
      })
    },

    getContents(
      { state, dispatch }: { state: StoreState; dispatch: Dispatch },
      lang: string
    ): Promise<boolean> {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_BASE}/wp-json/v1/get_contents`, {
            params: {
              lang: lang,
            },
          })
          .then((res) => {
            state.contents = res.data

            // プリロード処理
            const audios: string[] = []
            if (state.contents?.greeting.audio)
              audios.push(state.contents?.greeting.audio)

            state.contents?.guide.forEach((item: Guide) => {
              if (item.audio) audios.push(item.audio)
            })
            state.contents?.quiz.forEach((item: Quiz) => {
              if (item.audio) audios.push(item.audio)
              if (item.comment.audio) audios.push(item.comment.audio)
            })

            for (let i = 0; i < audios.length; i++) {
              const link = document.createElement('link')
              link.setAttribute('rel', 'preload')
              link.setAttribute('as', 'audio')
              link.setAttribute('href', audios[i])
              link.onload = () => document.head.removeChild(link)
              document.head.appendChild(link)
              // const audio = document.createElement('audio')
              // audio.src = audios[i]
            }

            return resolve(true)
          })
          .catch(() => {
            dispatch('showErrorModal', 'contents')
            return reject(false)
          })
      })
    },
  },

  getters: {
    getGuideContents(state: StoreState): any {
      interface Section {
        [key: string]: {
          label?: string
          guide: Guide[]
          quiz: Quiz[]
        }
      }

      if (!state.contents || !state.contents.guide) return []

      let sectionName = ''
      const res: Section = {}
      state.contents.guide.forEach((guide: Guide, index: number): void => {
        if (!guide.space) return

        if (sectionName !== guide.space.slug) {
          sectionName = guide.space.slug
          res[sectionName] = {
            label: guide.space.label,
            guide: [],
            quiz: [],
          }
        }
        guide.index = index + 1

        res[sectionName].guide.push(guide)
      })

      sectionName = ''
      state.contents.quiz.forEach((quiz: Quiz, index: number): void => {
        if (sectionName !== quiz.space.slug) sectionName = quiz.space.slug

        quiz.index = index + 1
        res[sectionName].quiz.push(quiz)
      })

      return res
    },

    getAudioGuide(state: StoreState): Guide[] {
      if (!state.contents || !state.contents.greeting || !state.contents.guide)
        return []

      const res: Guide[] = [
        {
          name: 'ごあいさつ',
          audio: state.contents.greeting.audio,
          description:
            state.contents.greeting.description.replace(
              /(\n|\r\n|\r)/g,
              '<br/>'
            ) || '',
        },
      ]

      return res.concat(state.contents?.guide)
    },

    getLang(state: StoreState) {
      return state.lang
    },
  },
})
