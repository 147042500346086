
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'OfflineView',

  setup() {
    const store = useStore()
    const lang = store.getters.getLang
    const alt = {
      ja: 'インターネットにつないで使ってね！',
      en: 'Make sure you are connected to the internet when you use the app!',
      zh: '请连网使用哦！',
      ko: '인터넷에 연결하여 사용하세요!',
    }

    return {
      lang,
      alt,
    }
  },
})
