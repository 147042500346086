
// 共通のCSS読み込み
import '@/assets/scss/app.scss'
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import Loader from '@/components/elements/Loader.vue'
import OfflineView from '@/components/block/OfflineView.vue'
import ErrorModal from '@/components/block/ErrorModal.vue'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

export default defineComponent({
  components: {
    Loader,
    OfflineView,
    ErrorModal,
  },

  setup() {
    const store = useStore()
    const isAuthorized = ref(false)
    const isAndroid = ref(false)

    const reauthKey = (): void => {
      try {
        // 秘密のカギ未認証でCookieもなければ実行しない
        if (!isAuthorized.value && !Cookies.get(app_cookie.todays_key)) return

        // 認証済みなのにCookieが無い場合はリロード(期限切れ)
        if (isAuthorized.value && !Cookies.get(app_cookie.todays_key)) {
          window.location.reload()
          return
        }

        isAuthorized.value = true
        const key = Cookies.get(app_cookie.todays_key)

        store
          .dispatch('authenticateKey', key)
          .then((result: boolean) => {
            // Cookieがあるのにカギが違う場合はCookieを削除してリロード
            if (!result) {
              Cookies.remove(app_cookie.todays_key)
              window.location.reload()
            }
          })
          .catch(() => {
            console.log('onRejected function called: ')
          })
      } catch (error) {
        console.log('error')
      }
    }

    const setOS = (params: string): void => {
      let paramsObj: Record<string, string> = {}
      if (params) {
        const paramsArray = params.split('&')

        paramsArray.forEach((element: string): void => {
          const el = element.split('=')
          paramsObj[el[0]] = el[1]
        })

        if (paramsObj.os) Cookies.set(app_cookie.os, paramsObj.os)
      }

      const os = paramsObj.os || Cookies.get(app_cookie.os)

      if (os === 'android') isAndroid.value = true
    }

    const successAuthorize = () => {
      isAuthorized.value = true
    }

    const isShowAuth = computed(() => {
      return !isAuthorized.value
    })

    setOS(location.search.replace('?', ''))
    reauthKey()
    window.addEventListener('focus', reauthKey)

    return {
      isShowAuth,
      isAndroid,
      reauthKey,
      successAuthorize,
    }
  },
})
