<template>
  <div class="overlay">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Overlay',
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(51, 51, 51, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
