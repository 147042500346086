<template>
  <div class="loader" role="status">
    <div class="loader_inner"></div>
    <div class="loader_text">
      <img src="img/common/loading.svg" alt="loading…" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Loader',
}
</script>

<style lang="scss" scoped>
/*!
 * css-loaders
 * (c) 2014 Luke Haas
 * @license https://github.com/lukehaas/css-loaders/blob/step2/LICENSE
 */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(51, 51, 51, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_inner {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: 55px;
    margin-bottom: 55px;
    position: relative;
    transform: translateZ(0);
    animation: load 1.1s infinite ease;
  }
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.7),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.5),
      2.5em 0 0 0 rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.7),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.5),
      0 2.5em 0 0 rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.2),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.7),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2),
      2.5em 0 0 0 rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0 rgba(255, 255, 255, 0.2),
      0 2.5em 0 0 rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(255, 255, 255, 0.5),
      -2.6em 0 0 0 rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0 rgba(255, 255, 255, 0.2);
  }
}
</style>
