<template>
  <div class="offline">
    <div class="offline_contents">
      <h1 class="offline_title">
        <img :src="`img/offline/${lang}/title.svg`" :alt="alt[lang]" />
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'OfflineView',

  setup() {
    const store = useStore()
    const lang = store.getters.getLang
    const alt = {
      ja: 'インターネットにつないで使ってね！',
      en: 'Make sure you are connected to the internet when you use the app!',
      zh: '请连网使用哦！',
      ko: '인터넷에 연결하여 사용하세요!',
    }

    return {
      lang,
      alt,
    }
  },
})
</script>

<style lang="scss" scoped>
.offline {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var.$color-primary-bg;
  background-image: url('/img/common/dot_blue.svg');
}
.offline_contents {
  margin: 20px;
  border-radius: 20px;
  background-color: #fff;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.offline_title {
  text-align: center;
  margin-top: -25px;
}
</style>
