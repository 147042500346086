
import { defineComponent } from 'vue'
import Overlay from '@/components/elements/Overlay.vue'
import Button from '@/components/elements/Button.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ErrorModal',

  components: {
    Overlay,
    Button,
  },

  setup() {
    const reload = (): void => {
      window.location.reload()
      return
    }
    const store = useStore()
    const lang = store.getters.getLang
    const contents = {
      ja: {
        title: 'エラーが発生しました',
        text: '時間をおいて<br />もう一度お試しください。',
        btnRetry: '再試行',
        btnClose: 'とじる',
      },
      en: {
        title: 'An error has occurred',
        text: 'Click Re-try to try again.',
        btnRetry: 'Re-try',
        btnClose: 'Cancel',
      },
      zh: {
        title: '出现错误。',
        text: '请点击“重试”按钮重试您的请求。',
        btnRetry: '重试',
        btnClose: '取消',
      },
      ko: {
        title: '오류가 발생했습니다.',
        text: '‘다시 시도’ 버튼을 눌러 다시<br>시도하십시오.',
        btnRetry: '다시 시도',
        btnClose: '취소',
      },
    }

    return {
      reload,
      lang,
      contents,
    }
  },
})
