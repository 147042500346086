import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/:lang(\\ja|en|zh|ko)',
    name: 'Main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!navigator.onLine) {
    store.commit('setOfflineView')
  }

  if (to.name === 'Main') {
    switch (to.params.lang) {
      case 'en':
        document.title = 'Gabby Guide'
        break
      case 'zh':
        document.title = '解说电话'
        break
      case 'ko':
        document.title = '이야기 전화'
        break
      default:
        document.title = 'おはなしデンワ'
    }
    document.documentElement.lang = to.params.lang as string
  }
  if (to.name === 'Home') {
    document.title = 'おはなしデンワ'
    document.documentElement.lang = 'ja'
  }

  next()
})

export default router
