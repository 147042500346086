<template>
  <button
    type="button"
    :disabled="disabled"
    class="btn"
    :class="[colorClass, sizeClass, shadowClass, { 'is-disabled': disabled }]"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Button',

  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      validator: (value: string) => {
        return ['primary', 'border', 'secondary', 'yellow', 'white'].includes(
          value
        )
      },
      default: 'primary',
    },
    size: {
      validator: (value: string) => {
        return ['sm', 'md', 'lg'].includes(value)
      },
      default: 'md',
    },
    shadow: {
      validator: (value: string) => {
        return ['primary', 'secondary', 'yellow', 'white'].includes(value)
      },
      default: 'white',
    },
    handleClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      colorClass: `btn-color-${props.color}`,
      sizeClass: `btn-size-${props.size}`,
      shadowClass: `btn-shadow-${props.shadow}`,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.btn-color {
  &-primary {
    background-color: var.$color-primary;
  }

  &-border {
    border: 4px solid var.$color-primary;
    background-color: #fff;
    color: var.$color-primary;
  }

  &-secondary {
    background-color: var.$color-secondary;
  }

  &-yellow {
    background-color: var.$color-yellow;
  }

  &-white {
    background-color: #fff;
    color: var.$color-primary;
  }
}

.btn-size {
  &-sm {
    height: 50px;
    border-radius: 25px;
    font-size: 1.8rem;
  }

  &-md {
    height: 60px;
    border-radius: 30px;
  }

  &-lg {
    height: 70px;
    border-radius: 35px;
  }
}

.btn-shadow {
  &-primary {
    box-shadow: 0px 3px 6px rgba(0, 68, 92, 0.2);
  }

  &-border {
    box-shadow: 0px 3px 6px rgba(#333, 0.2);
  }

  &-secondary {
    box-shadow: 0px 3px 6px rgba(249, 131, 141, 0.2);
  }

  &-yellow {
    box-shadow: 0px 3px 6px rgba(209, 143, 30, 0.2);
  }

  &-white {
    box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.2);
  }
}
.btn.is-disabled {
  background-color: var.$color-inactive;
  color: #fff;
  box-shadow: none;
  cursor: auto;
}
</style>
